import React, { Component } from 'react';
import img from '../img/img.png'
export default class componentName extends Component {
  render() {
    return (
      <div className='footer'>
          <div className='footer_cont'>
              <div className='footer_top' >
                <img src={img} alt='' style={{width:'10%'}} />
                <div className='footer_right'>
                    <a className='nr' href='https://icesimbasdkweb.icesimba.com/privacy.html'>
                    隐私政策
                    </a>
                 
                </div>
              </div>  
              <div className='footer_bottom' style={{"text-align":"center"}}>
                <p className='bnr' >       
                      健康游戏忠告 抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活<br></br>
                      网站备案/许可证号：<a href='https://beian.miit.gov.cn/#/Integrated/index'>京ICP备15052514号      京ICP证161407号 </a><br></br>
                      网文编号：<a href='https://icesimba.com/wangwen.png'>京网文〔2023)2238-085号</a><br></br>
                      北京冰狮科技有限公司 © 版权所有 互联网违法不良信息举报电话：4006638866
                </p>
               </div>
          </div>
      </div>
    );
  }
}
